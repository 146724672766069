// src/hooks/useIsMobile.ts
import { useEffect, useState } from "react";

// Custom hook to detect if the device is mobile
const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    // Only run in the browser (client-side)
    if (typeof window !== 'undefined') {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
      };

      handleResize(); // Set the initial state based on window size

      window.addEventListener("resize", handleResize);

      // Cleanup listener on unmount
      return () => window.removeEventListener("resize", handleResize);
    }
  }, []);

  return isMobile;
};

export default useIsMobile;
