import React from 'react';
import { Link } from 'gatsby';
import { Product } from '../../services/models';
import { getProductImageUrl } from '../../utils';
import styles from './productItem.module.scss';
import product_placeholder from '../../assets/product_placeholder.png';

interface Props {
  productData: Product;
  onProductPress: Function;
}

export const ProductItem = (props: Props) => {
  const { productData, onProductPress } = props;
  return (
    <span
      // role='button'
      // to={`/products/${productData.slug}`}
      className={styles.productItemWrapper}
      key={productData?.id}
      onClick={() => onProductPress(productData)}
    >
      <img
        alt={productData?.title || "Product"}
        className={styles.productImg}
        src={
          productData?.image
            ? getProductImageUrl(productData?.image)
            : product_placeholder
        }
      />
      <div className={styles.infoWrapper}>
        <div className={styles.titleText}>{productData?.title}</div>
        <p className={styles.priceText}>${productData?.price.incl_tax}</p>
      </div>
    </span>
  );
};

export default ProductItem;
