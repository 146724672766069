import React, { useState, useEffect } from 'react';
import { navigate } from '@reach/router';
import { isEmpty } from 'lodash';
import Loader from 'components/common/Loader';
import StatusMessage from 'components/common/StatusMessage';
import ImageGallery from 'react-image-gallery';
import { ProductAttributeValues } from 'models';
import { isLoggedIn } from 'services/auth.api';
import {
  constructProductUrl,
  getProductImageUrl,
  trackAddOrRemoveItem,
  trackProductDetailsView,
  getProductEntity,
  isProductOfAttributeValue,
  productList,
} from '../../src/utils';
import { Button, Tabs, Tab, Accordion, Card } from 'react-bootstrap';
import { ProductItem } from 'components/ProductItem';
import styles from './productDetails.module.scss';
import Overlay from 'components/Overlay';
import {
  useAppDispatch,
  useAppSelector,
  addLine,
  toggleOneOffSubscription,
  // removeLine,
  productIdsInBasketSelector,
  basketProductLineMapSelector,
} from 'state';
import product_placeholder from '../assets/product_placeholder.png';
import { RadioButton } from 'components/common/Form/RadioButton';
import chevbot from '../assets/chev_bot.png';
import { getUserSubscription } from 'services/subscription.api';
import { getAllPets } from 'services/pets.api';
import {
  createSubscriptionTreat,
  updateSubscriptionTreat,
} from 'services/subscriptionTreats.api';
import { Link, graphql } from 'gatsby';
import CustomSpinner from 'components/Spinner';
import { useNotificationToastContext } from 'components/common/NotificationToast';
import useHasMounted from '../hooks/usesHasMounted';
import MainLayout from '../layouts/mainLayout';
import { trackProductViewInSolve } from '../utils/solve_analytics';
import { useSubscriptionContext } from '../pages/account';
import Breadcrumbs, { BreadcrumbItem } from 'components/Breadcrumbs';
import { PurchaseType, StatusType } from '../types';
import CheckoutDrawer from '../components/CheckoutDrawer/CheckoutDrawer';
import useIsMobile from '../hooks/useIsMobile';


const chickendogBoxes = productList.map(it => Number(it.value));
export default (props: any) => {
  const hasMounted = useHasMounted();
  const dispatch = useAppDispatch();

  const {
    pageContext: { product },
    data: {
      allRestApiApiProducts: { nodes },
    },
  } = props;
  const allProducts = nodes || [];
  const { id: urlProductId, slug: urlSlug } = product;
  const [hasTracked, setHasTracked] = useState(false);
  const [productQty, setProductQty] = useState(1);
  const [maxLimitError, setMaxLimitError] = useState('');
  const [activeTab, setActiveTab] = useState('addInfo');
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { isAllLoading } = useAppSelector(state => state.products);
  const isLoading = false;
  const isMobile = useIsMobile();

  const productIds = useAppSelector(productIdsInBasketSelector);
  const lines = useAppSelector(state => state.basket.lines);
  const productLineMap = useAppSelector(basketProductLineMapSelector);
  if (urlProductId && allProducts.length) {
    const slug = getProductEntity(
      allProducts,
      'id',
      Number(urlProductId),
      'slug',
    );
    slug && navigate('/products/' + slug);
  }
  const productId = urlProductId + '';
  const basket = useAppSelector(state => state.basket.basket);
  const productData = product;
  const [breadcrumbs, setBreadcrumbs] = useState < BreadcrumbItem[] > ([]);

  useEffect(() => {
    if (productId) {
      trackProductViewInSolve(productId);
    }
  }, [productId]);
  const { subscriptions } = useSubscriptionContext();
  useEffect(() => {
    if (!isEmpty(productData)) {
      if (!hasTracked) {
        trackProductDetailsView(productData);
        setHasTracked(true);
      }
      const breadcrumbItems: BreadcrumbItem[] = [
        {
          name: 'Products',
          link: '/products/',
        },
      ];

      if (
        productData.categories &&
        productData.categories.length &&
        productData.categories.filter(it => it.ancestors && it.ancestors.length)
          .length
        // productData.categories[0].ancestors &&
        // productData.categories[0].ancestors.length
      ) {
        const selectedCatgory = productData.categories.find(
          it => it.slug === window?.history?.state?.parent,
        );
        (selectedCatgory || productData.categories[0]).ancestors.forEach(
          category => {
            breadcrumbItems.push({
              name: category.name,
              link: '/products/?category=' + category.slug,
            });
          },
        );
      }
      breadcrumbItems.push({
        link: '/products/' + productData.slug,
        name: productData.title,
      });
      setBreadcrumbs(breadcrumbItems);
    }
  }, [productData]);

  const {
    calories_per_kg = 0,
    crude_protein = 0,
    crude_fibre = 0,
    crude_fat = 0,
    moisture = 0,
    phosphorus = 0,
    calcium = 0,
    grams_per_cup = 0,
  } = productData?.nutritional_information || {};

  const showNutritionalInfo = Boolean(
    crude_protein ||
    crude_fibre ||
    crude_fat ||
    moisture ||
    phosphorus ||
    calcium,
  );

  const showNutritionalDescription = productData?.nutritional_description;
  const showAdditionalInfoTab = productData?.additional_information;

  const showTabs =
    showNutritionalInfo || showNutritionalDescription || showAdditionalInfoTab;

  const caloriesPerCup = calories_per_kg
    ? ((calories_per_kg * grams_per_cup) / 1000).toFixed(2)
    : 0;

  const prodSuggestions = allProducts
    .filter(
      item =>
        item.id !== productData.id &&
        item.is_public &&
        isProductOfAttributeValue(item, ProductAttributeValues.ONE_OFF),
    )
    .slice(0, 4);

  // const hasSubscriptionProductInBasket = () => {
  //   const productsInBasket = allProducts.filter(
  //     product => productIds.indexOf(product.id.toString()) !== -1,
  //   );
  //   return productsInBasket.filter(
  //     product => product.product_class !== ProductClassType.ONEOFF,
  //   );
  // };

  const addToCart = async (isSubscribe?: true, quantity?: number) => {
    const newProductQty = quantity || productQty;
    const lineIds = productId && productLineMap[productId];
    const line = lineIds && lines.find(line => lineIds?.includes(line.id));
    const maxLimit = productData.items_in_cart_limit;
    const lineQuantity = line?.quantity || 0;
    if (maxLimit < lineQuantity + Number(newProductQty)) {
      const canAdd = maxLimit - lineQuantity;
      setMaxLimitError(
        `Max limit ${maxLimit}. Cannot add more than ${canAdd}, you have ${lineQuantity} in your cart`,
      );
      return;
    }
    setMaxLimitError('');
    // const subscriptionProducts = hasSubscriptionProductInBasket();
    // if (subscriptionProducts.length) {
    //   const allLinePromises = subscriptionProducts.map(subscriptionProduct => {
    //     return productLineMap[subscriptionProduct.id].map(lineId => {
    //       return dispatch(removeLine(lineId));
    //     });
    //   });
    //   await Promise.all(allLinePromises.flat());
    // }
    setDrawerOpen(true);
    await dispatch(
      addLine({
        url: constructProductUrl(productData.id),
        quantity: newProductQty,
        purchaseType: PurchaseType.ONE_OFF,
        skipFetchLines: true,
      }),
    );
    if (isSubscribe) {
      await dispatch(
        toggleOneOffSubscription({
          basketId: basket?.id,
          isSubscribe: true,
          frequency: basket?.frequency,
        }),
      );
    } else {
      await dispatch(
        toggleOneOffSubscription({
          basketId: basket?.id,
          isSubscribe: false,
        }),
      );
    }
    trackAddOrRemoveItem(productData, 'add', newProductQty);
  };

  const maxQuantity = productData?.items_in_cart_limit || 6;
  const quantityArray = [...Array(maxQuantity).keys()];

  const renderNutritionTable = () => (
    <div className={styles.tableWrapper}>
      <div className="col-sm-12 no-pdd">
        <div className={styles.nutriRow}>
          <div className="col-4 no-pdd">
            <div>
              <strong>Crude Protein</strong>
            </div>
          </div>
          <div className="col-2">
            <div>{crude_protein}%</div>
          </div>
          <div className="col-4">
            <div>
              <strong>Moisture</strong>
            </div>
          </div>
          <div className="col-2">
            <div>{moisture}%</div>
          </div>
        </div>
        <div className={styles.nutriRow}>
          <div className="col-4 no-pdd">
            <div>
              <strong>Crude Fibre</strong>
            </div>
          </div>
          <div className="col-2">
            <div>{crude_fibre}%</div>
          </div>
          <div className="col-4">
            <div>
              <strong>Calcium</strong>
            </div>
          </div>
          <div className="col-2">
            <div>{calcium}%</div>
          </div>
        </div>

        <div className={styles.nutriRow}>
          <div className="col-4 no-pdd">
            <div>
              <strong>Crude Fat</strong>
            </div>
          </div>
          <div className="col-2">
            <div>{crude_fat}%</div>
          </div>
          <div className="col-4">
            <div>
              <strong>Phosphorus</strong>
            </div>
          </div>
          <div className="col-2">
            <div>{phosphorus}%</div>
          </div>
        </div>
      </div>
    </div>
  );

  const renderProduct = () => {
    const images = (productData?.images || []).map(image => {
      const imgUrl = getProductImageUrl(image.original);
      return {
        original: imgUrl,
        thumbnail: imgUrl,
      };
    });

    if (!images.length) {
      images.push({
        original: product_placeholder,
        thumbnail: product_placeholder,
      });
    }
    return (
      <div className={styles.topRowWrapper}>
        <div className={styles.imageWrapper}>
          <ImageGallery
            items={images}
            showPlayButton={false}
            showFullscreenButton={false}
            showThumbnails={images.length > 1}
          />
        </div>
        <div className={styles.descWrapper}>
          {!isMobile ? <h2>{productData?.title}</h2> : <h4>{productData?.title} </h4>}
          <div className={styles.priceDetails}>
            {/* <h4 className="no-mgn">${productData?.price?.incl_tax}</h4> */}
            {/* <p className={styles.deliveryText}>
              {' '}
              +{' '}
              {(productData?.shipping_charge || 0) > 0
                ? '$' + productData?.shipping_charge
                : 'Free delivery'}
            </p> */}
          </div>
          {productData?.is_public ? (
            <AddToCart
              quantityArray={quantityArray}
              setProductQty={setProductQty}
              productQty={productQty}
              addToCart={addToCart}
              productData={productData}
            />
          ) : (
            <StatusMessage
              message="The product is unavailable currently."
              type={StatusType.info}
            />
          )}
          <p
            className="mgn-top-30"
            dangerouslySetInnerHTML={{ __html: productData?.description }}
          />


          <div className="formikError">{maxLimitError}</div>
          {/* <div style={{ alignSelf: 'flex-end' }}>
            <h5>Want to save time & money?</h5>
            <div className={styles.createSubLink}>Create a Subscription Order</div>
          </div> */}
        </div>
      </div>
    );
  };

  const renderTabs = () => (
    <div className={styles.middleRowWrapper}>
      <Tabs
        defaultActiveKey={showAdditionalInfoTab ? 'addInfo' : 'nutriInfo'}
        onSelect={tabName => setActiveTab(tabName || '')}
      >
        {showAdditionalInfoTab && (
          <Tab
            eventKey="addInfo"
            title="Additional Information"
            tabClassName={
              activeTab === 'addInfo' ? styles.tabTitleActive : styles.tabTitle
            }
          >
            <div
              className="mgn-top-20"
              dangerouslySetInnerHTML={{
                __html: productData?.additional_information,
              }}
            />
          </Tab>
        )}
        {(showNutritionalInfo || showNutritionalDescription) && (
          <Tab
            eventKey="nutriInfo"
            title="Nutritional Info"
            tabClassName={
              activeTab === 'nutriInfo'
                ? styles.tabTitleActive
                : styles.tabTitle
            }
          >
            <div className={styles.nutriWrapper}>
              <div
                className={styles.nutriDesc}
                dangerouslySetInnerHTML={{
                  __html: productData?.nutritional_description
                    ? productData?.nutritional_description
                    : 'No information available',
                }}
              />
              {showNutritionalInfo && (
                <div className={styles.nutriTable}>
                  <h5>Nutritional Info</h5>
                  {renderNutritionTable()}
                  <p className={styles.energyInfo}>
                    {calories_per_kg}kcal per kg (1 scoop = 1 cup ={' '}
                    {grams_per_cup} gram = {caloriesPerCup}kcal)
                  </p>
                </div>
              )}
            </div>
          </Tab>
        )}
      </Tabs>
    </div>
  );

  const renderSuggestions = () => (
    <div className={styles.bottomRowWrapper}>
      <h5>You might also like</h5>
      <div className={styles.prodSuggestionList}>
        {prodSuggestions.map(prodItem => (
          <ProductItem
            key={prodItem.id}
            productData={prodItem}
            onProductPress={() => navigate(`/products/${prodItem.slug}`)}
          />
        ))}
      </div>
    </div>
  );

  if (!hasMounted) {
    return null;
  }

  return (
    <MainLayout>
      <div className={styles.productDetailsWrapper}>
        {isLoading && <Loader height="200%" />}
        <div className={styles.header}>
          {!isMobile && <Breadcrumbs breadcrumbs={breadcrumbs} />}
        </div>

        {!isLoading &&
          (productData && productData.id ? (
            renderProduct()
          ) : (
            <StatusMessage
              message="Sorry, No Product found!"
              type={StatusType.error}
            />
          ))}
        {showTabs && renderTabs()}
        {renderSuggestions()}
        <Overlay show={drawerOpen} onClick={() => setDrawerOpen(false)} />
        <CheckoutDrawer
          open={drawerOpen}
          closeDrawer={() => setDrawerOpen(false)}
        />
      </div>
    </MainLayout>
  );
};

const AddToCart = ({ quantityArray, productQty, addToCart, productData }) => {
  const userLoggedIn = isLoggedIn();
  const [loading, SetLoading] = useState(false);
  const [activeKey, setActiveKey] = useState('onetimepurchase');

  const [formData, SetformData] = useState < {
    selected: string;
    qty?: number;
    type?: string;
    subscription?: string;
    disable?: boolean;
    maxQuantity?: number;
  } > ({
    selected: 'onetimepurchase',
    qty: 1,
  });
  interface Pet {
    name: string;
    // type: string;
  }

  interface Subscription {
    id: string;
    pet_details: Pet | null;
    label: string;
  }
  const [subscriptions, SetSubscription] = useState < Subscription[] > ([]);
  const { showNotificationToast } = useNotificationToastContext();

  useEffect(() => {
    fetchPetAndSubs();
  }, [productQty]);
  const fetchPetAndSubs = () => {
    Promise.all([getAllPets(), getUserSubscription()]).then(res => {
      const [pets, subscriptions] = res;
      const finalData: Subscription[] = subscriptions
        .map(it => {
          const petId = !!it.pet
            ? it.pet
              .split('/')
              .filter(it => it)
              .at(-1)
            : '';
          const petdetails = petId
            ? pets.find(it => it.id + '' == petId + '')
            : null;
          const newLabel = `Sides Subscription
          ${it.display_id && it.display_id > 1 ? ' ' + it.display_id : ''}`;
          return {
            id: it.id + '',
            pet_details:
              petId && petdetails
                ? { name: petdetails.name, id: petdetails.id }
                : null,
            treats: petId ? it?.treats || [] : it?.products || [],
            label: petId
              ? pets.find(it => it.id + '' == petId + '')?.name
              : newLabel,
          };
        })
        .filter(it => {
          if (it.pet_details) {
            return !!isProductOfAttributeValue(
              productData,
              ProductAttributeValues.ADD_ON,
            );
          } else {
            return !!isProductOfAttributeValue(
              productData,
              ProductAttributeValues.ONE_OFF,
            );
          }
        });
      SetSubscription(finalData);
    });
  };
  const onValChange = (val = '') => {
    switch (val) {
      case 'onetimepurchase':
        SetformData({ selected: val, qty: 1 });
        break;
      case 'subscribe':
        SetformData({ selected: val, qty: 1 });
        break;
      case 'addtonextorder':
        let disabled = false;
        let maxQuantity = productData?.items_in_cart_limit || 6;
        if (subscriptions.length) {
          const findTreat = (subscriptions[0].treats || []).find(
            it => getId(it.product) == productData.id,
          );
          if (findTreat) {
            disabled =
              findTreat.quantity === (productData?.items_in_cart_limit || 6);
            maxQuantity = maxQuantity - findTreat.quantity;
          }
        }
        SetformData({
          selected: val,
          qty: 1,
          type: 'every_order',
          subscription: subscriptions.length ? subscriptions[0].id : undefined,
          disable: disabled,
          maxQuantity,
        });
        break;
      default:
        break;
    }
  };
  const onSubmit = (type = '') => {
    switch (type) {
      case 'onetime':
        addToCart(undefined, formData.qty);
        break;
      case 'subscribe':
        if (chickendogBoxes.includes(productData.id)) {
          navigate(userLoggedIn ? '/account/dashboard/' : '/getstarted/');
        } else {
          addToCart(true, formData.qty);
        }
        break;
      case 'addtonext':
        const findSubsc = subscriptions.find(
          it => it.id == formData.subscription,
        );
        if (findSubsc) {
          if (findSubsc?.pet_details?.id) {
            if (
              isProductOfAttributeValue(
                productData,
                ProductAttributeValues.ADD_ON,
              )
            ) {
              handleTreatProductChange(findSubsc, productData);
            }
          } else {
            if (
              isProductOfAttributeValue(
                productData,
                ProductAttributeValues.ONE_OFF,
              )
            ) {
              handleTreatProductChange(findSubsc, productData);
            }
          }
        }
        break;
      default:
        break;
    }
  };

  const onValueChange = (data: any) => {
    SetformData({ ...formData, ...data });
  };
  const { selected } = formData;
  const getId = (url = '') => {
    return url
      .split('/')
      .filter(it => it)
      .at(-1);
  };

  const handleTreatProductChange = async (subs: any, product: any) => {
    // let ltreat = null;
    let response = null;
    try {
      SetLoading(true);
      const ifExist = (subs?.treats || []).find(
        it => getId(it.product) == product.id,
      );
      if (ifExist) {
        const newQuantity = ifExist.quantity + formData.qty;
        response = await updateSubscriptionTreat(
          subs.id,
          product.url,
          newQuantity,
          ifExist.id,
          subs?.pet_details?.id,
          formData.type === 'only_once',
        );
        onValueChange({
          maxQuantity: (productData?.items_in_cart_limit || 6) - newQuantity,
          disable: (productData?.items_in_cart_limit || 6) === newQuantity,
        });
      } else {
        response = await createSubscriptionTreat(
          subs.id,
          product.url,
          formData.qty,
          subs?.pet_details?.id,
          formData.type === 'only_once',
        );
        onValueChange({
          maxQuantity: (productData?.items_in_cart_limit || 6) - formData.qty,
          disable: (productData?.items_in_cart_limit || 6) === formData.qty,
        });
      }
      showNotificationToast(`"Added successfully"`);
      // fetchPetAndSubs();
      SetLoading(false);
      navigate('/account/dashboard/');
    } catch (e) {
      SetLoading(false);
      console.log(e);
      //TODO handle error
    }
  };
  return (
    <div className={styles.addToCartContainer}>
      <Accordion activeKey={selected} onSelect={key => onValChange(key)}>
        <Card>
          <Card.Header
            className={selected === 'onetimepurchase' ? '' : 'notactive'}
          >
            <div className="cardHeaderContainer">
              <div>
                <RadioButton
                  name="address"
                  id={'onetimepurchase'}
                  value={'onetimepurchase'}
                  checked={selected === 'onetimepurchase'}
                  onChange={() => onValChange('onetimepurchase')}
                  label={'One Time Purchase'}
                />
              </div>
              <div>
                <span className="discount-message">
                  ${productData?.price?.incl_tax}
                </span>
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  eventKey="onetimepurchase"
                >
                  <img
                    className={`cur-pointer ${selected === 'onetimepurchase' ? 'flipImage' : ''
                      }`}
                    src={chevbot}
                    alt=""
                  />
                </Accordion.Toggle>
              </div>
            </div>
          </Card.Header>
          <Accordion.Collapse eventKey="onetimepurchase">
            <Card.Body>
              <div className="newContainer_item_controlls">
                <select
                  className="cust-sel quantity"
                  name="quantity"
                  value={formData.qty}
                  onChange={e => onValueChange({ qty: Number(e.target.value) })}
                >
                  {quantityArray.map(index => (
                    <option key={index} value={index + 1}>
                      {index + 1}
                    </option>
                  ))}
                </select>
                <Button
                  className={'btn-def btn btn-primary'}
                  onClick={() => onSubmit('onetime')}
                  disabled={false}
                >
                  Add to cart
                </Button>
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        {productData.is_subscribable && (
          <Card>
            <Card.Header
              className={selected === 'subscribe' ? '' : 'notactive'}
            >
              <div className="cardHeaderContainer">
                <div>
                  <RadioButton
                    name="address"
                    id={'subscribe'}
                    value={'subscribe'}
                    checked={selected === 'subscribe'}
                    onChange={() => onValChange('subscribe')}
                    label={'Subscribe'}
                  />
                </div>
                <div>
                  <span className="discount-message">
                    {`$${productData?.subscription_price ||
                      productData?.price_incl_tax}`}
                    &nbsp;&nbsp;
                    <span className="discountPrice">
                      {productData?.price_incl_tax !=
                        productData?.subscription_price
                        ? `(Saves ${+productData.percentage.toFixed(2)}%)`
                        : ''}
                    </span>
                  </span>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="subscribe"
                  >
                    <img
                      className={`cur-pointer ${selected === 'subscribe' ? 'flipImage' : ''
                        }`}
                      src={chevbot}
                      alt=""
                    />
                  </Accordion.Toggle>
                </div>
              </div>
            </Card.Header>
            <Accordion.Collapse eventKey="subscribe">
              <Card.Body>
                <div
                  className={`newContainer_item_controlls ${chickendogBoxes.includes(productData.id) ? 'reverse' : ''
                    }`}
                >
                  {!chickendogBoxes.includes(productData.id) && (
                    <select
                      className="cust-sel quantity"
                      name="quantity"
                      value={formData.qty}
                      onChange={e =>
                        onValueChange({ qty: Number(e.target.value) })
                      }
                    >
                      {quantityArray.map(index => (
                        <option key={index} value={index + 1}>
                          {index + 1}
                        </option>
                      ))}
                    </select>
                  )}
                  <Button
                    className={'btn-def btn btn-primary'}
                    onClick={() => onSubmit('subscribe')}
                    disabled={false}
                  >
                    Subscribe
                  </Button>
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        )}
        {userLoggedIn &&
          productData.is_public &&
          (!chickendogBoxes.includes(productData.id) && !productData.title.includes("Taste Tester")) && (
            <Card
              className={selected === 'addtonextorder' ? 'activeHeader' : ''}
            >
              <Card.Header
                className={selected === 'addtonextorder' ? '' : 'notactive'}
              >
                <div className="cardHeaderContainer">
                  <div>
                    <RadioButton
                      name="address"
                      id={'addtonextorder'}
                      value={'addtonextorder'}
                      checked={selected === 'addtonextorder'}
                      onChange={() => onValChange('addtonextorder')}
                      label={'Add to next order'}
                    />
                  </div>
                  <div>
                    <span className="discount-message">
                      <>
                        {`$${productData?.subscription_price ||
                          productData?.price_incl_tax}`}
                        &nbsp;&nbsp;
                        <span className="discountPrice">
                          {productData?.price_incl_tax !=
                            productData?.subscription_price
                            ? `(Saves ${+productData.percentage.toFixed(2)}%)`
                            : ''}
                        </span>
                      </>
                    </span>
                    <Accordion.Toggle
                      as={Button}
                      variant="link"
                      eventKey="addtonextorder"
                    >
                      <img
                        className={`cur-pointer ${selected === 'addtonextorder' ? 'flipImage' : ''
                          }`}
                        src={chevbot}
                        alt=""
                      />
                    </Accordion.Toggle>
                  </div>
                </div>
              </Card.Header>
              <Accordion.Collapse eventKey="addtonextorder">
                <Card.Body>
                  <CustomSpinner isLoading={loading}>
                    <div className="newContainer_item_controlls form-container">
                      <div>
                        <span>Subscription</span>
                        <select
                          className="cust-sel form-items"
                          name="subscription"
                          value={formData.subscription}
                          onChange={e => {
                            let disabled = false;
                            let maxQuantity =
                              productData?.items_in_cart_limit || 6;
                            const Treats =
                              subscriptions.find(it => it.id == e.target.value)
                                .treats || [];
                            const findTreat = Treats.find(
                              it => getId(it.product) == productData.id,
                            );
                            if (findTreat) {
                              disabled =
                                findTreat.quantity ===
                                (productData?.items_in_cart_limit || 6);
                              maxQuantity = maxQuantity - findTreat.quantity;
                            }
                            onValueChange({
                              subscription: e.target.value,
                              disable: disabled,
                              maxQuantity,
                            });
                          }}
                        >
                          {(subscriptions || []).map(({ id, label }, index) => (
                            <option key={id + 'subscriptions'} value={id}>
                              {label}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div>
                        <span>Quantity</span>
                        <select
                          disabled={formData?.disable || false}
                          className="cust-sel form-items"
                          name="quantity"
                          value={formData.qty}
                          onChange={e =>
                            onValueChange({ qty: Number(e.target.value) })
                          }
                        >
                          {[...Array(formData.maxQuantity).keys()].map(
                            index => (
                              <option key={index} value={index + 1}>
                                {index + 1}
                              </option>
                            ),
                          )}
                        </select>
                      </div>
                      <div>
                        <span>Order Type</span>
                        <select
                          disabled={formData?.disable || false}
                          className="cust-sel form-items"
                          name="order_type"
                          value={formData.type}
                          onChange={e =>
                            onValueChange({ type: e.target.value })
                          }
                        >
                          {[
                            { label: 'Every Order', value: 'every_order' },
                            { label: 'Only Once', value: 'only_once' },
                          ].map(({ label, value }, index) => (
                            <option key={label + index} value={value}>
                              {label}
                            </option>
                          ))}
                        </select>
                      </div>
                      {formData?.disable && (
                        <di>
                          <span>
                            * oops, you have exceeded the max quantity for this
                            product. Please visit{' '}
                            <Link
                              className="color-theme"
                              style={{ color: 'red' }}
                              to="/account/dashboard/"
                            >
                              {' '}
                              your dashboard{' '}
                            </Link>
                            to make changes.
                          </span>
                        </di>
                      )}
                      <div className="action-container">
                        <Button
                          className={'btn-def btn btn-primary add-to-button'}
                          onClick={() => onSubmit('addtonext')}
                          disabled={formData.disable}
                        >
                          Add to next order
                        </Button>
                      </div>
                    </div>
                  </CustomSpinner>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          )}
      </Accordion>
    </div>
  );
};
export const query = graphql`
  query {
    allRestApiApiProducts {
      nodes {
        title
        id
        endpointId
        image
        is_public
        is_subscribable
        is_suggestible
        items_in_cart_limit
        percentage
        price {
          currency
          excl_tax
          incl_tax
          tax
        }
        product_attributes {
          code
          name
          value
        }
        product_class
        product_type
        shipping_charge
        slug
        subscription_price
        weightage
        url
        description
        categories {
          id
          name
          slug
          ancestors {
            id
            name
            slug
          }
          descendants {
            id
            name
            slug
          }
        }
      }
      totalCount
    }
  }
`;
